<template>
  <div class="consensu-container" :style="cssVars" v-if="consent.configs">
    <div class="header">
        <div class="logo">
          <img src="/consensu.png" />
        </div>
        <div class="actions">
          <ul class="nav nav-tabs justify-content-center"> 
            <template  v-for="(tab,idx) in tabs"  :title="tab.title">
              <li class="nav-item" :key="idx">
                <a class="nav-link" :class="{ 'active': (idx === tabIndex)}" @click="setTab(idx)">{{tab.title}}</a>
              </li>
            </template>
          </ul>
        </div>
    </div>
    <div class="body">
      <b-tabs v-model="tabIndex" nav-class="d-none">
        <b-tab v-for="(tab,idx) in tabs" :key="idx" :title="tab.title">
          <component v-if="tab.comp" :is="tab.comp" :consent="consent" :version="version"></component>
        </b-tab>
      </b-tabs>
    </div>
    <div class="footer">
      <div class="actions">
        <a href="#link" class="btn btn-primary" >Termos de uso</a>
        <a href="#link" class="btn btn-primary" >Política de Privacidade</a>
        <a href="#link" class="btn btn-primary" >Política de Cookies</a>
        <a href="#link" class="btn btn-primary" >Optout</a>
      </div>
    </div>
  </div>
</template>

<script>
  import mixEvents from "../../mixins/events";
  
  export default {
    name: "box",
    mixins: [mixEvents],
    props: ["consent", "version"],
    components: {},
    data() {
      return {
        tabs: [
          {
            title: "Preferências de cookies",
            comp: this.load("cookies")
          },
          // {
          //   title: "Seus Direitos e Solicitações",
          //   comp: this.load("direitos")
          // },
          // {
          //   title: "Contatos de Privacidade",
          // },
          // {
          //   title: "Ouvidoria",
          // },
        ],
        tabIndex: 0,
        overlayClass: "",
        viewport: {
          desktop: {
            width: 800,
            height: 700,
          },
          mobile: {
            width: 400,
            height: 410,
          },
        },
      };
    },
    mounted() {
      this.setConfig();
      this.sendEvent("makeConsent", this.consent);
    },
    methods: {
        load(cmp) {
        return () => import(`./comps/${cmp}`);
      },
      setTab(idx) {
        this.tabIndex = idx;
      },
      setConfig() {
        const width  = window.screen.availWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
        const height = window.screen.availHeight|| document.documentElement.clientHeight||
        document.body.clientHeight;

        console.log(width, height);
        // this.sendEvent("configs", {width, height})
        this.sendEvent("makeViewport", this.viewport);
      },
      custom() {
        this.overlayClass = "open-sign-in";
      },
      back() {
        this.overlayClass = "open-sign-up";
      },
    },
    computed: {},
  };
</script>

<style lang="scss">
  @import "style.scss";
</style>
